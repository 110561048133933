<template>
    <div id="app">
        <div class="mx-auto main-container px-0 px-0">
            <the-header></the-header>
            <div class="content-container" :class="[isGamePage ? 'gameBg' : '']">
                <router-view></router-view>
                <main-features v-if="!isGamePage"></main-features>
            </div>
            <the-footer v-if="!isGamePage"></the-footer>
        </div>
        <FlashMessages></FlashMessages>
        <popup></popup>
        <AliveGamePopup></AliveGamePopup>
        <AfterWithdrawModal></AfterWithdrawModal>
       <FreeRoundSelector v-if="!userHasAliveGame && allowFreeRoundPopup != false"></FreeRoundSelector>
    </div>
</template>

<script>
    import TheHeader from '@/components/TheHeader'
    import TheFooter from '@/components/TheFooter'
    import MainFeatures from '@/components/MainFeatures'
    import Popup from '@/components/Popup'
    import FlashMessages from '@/components/FlashMessages'
    import AliveGamePopup from "./components/games/AliveGamePopup";
    import AfterWithdrawModal from "./components/payment/AfterWithdrawModal"
    import FreeRoundSelector from "@/components/games/FreeRoundSelector";

    export default {
        name: 'app',
        data(){
          return {
            allowAliveGamePopup: false,
            allowFreeRoundPopup: false
          }
        },
        components: {
            TheHeader,
            TheFooter,
            FlashMessages,
            Popup,
            MainFeatures,
            AliveGamePopup,
            AfterWithdrawModal,
            FreeRoundSelector
        },
        computed: {
            isGamePage() {
                return this.$route.name === 'TheGame' || this.$route.name === 'TheLiveGame'
            },
            freeRound() {
              return this.$store.getters.freeRound
            },
            userHasAliveGame() {
              const userData = this.$store.getters.userData
              if (!userData) {
                return false
              }
              const aliveGames = this.$store.getters.aliveGames
              return !!(aliveGames && aliveGames.length)
            },
        },
        methods :{
            isMobile() {
                return this.$_config.environment.mobile
            },
        },
        watch: {
            $route() {
                let chatBlock = document.getElementById("tawkchat-container");
                if (chatBlock) {
                    if (this.isGamePage && this.isMobile()) {
                        chatBlock.style.display = "none"
                    } else {
                        chatBlock.style.display = "block"
                    }
                }
            },
          alive: {
            deep: true,
            handler(newVal) {
              if (newVal) {
                this.allowAliveGamePopup = true
              }
            }
          },
          freeRound: {
            deep: true,
            handler(newVal) {
              if (newVal) {
                this.allowFreeRoundPopup = true
              }
            }
          }
        },
        mounted(){
          if(!this.isGamePage){
            this.$store.dispatch('checkUserFreeRound')
          }
        }
    }
</script>

