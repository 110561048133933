<template>
    <div class="login-container py-4 white-color col-12">

        <div class="page-title white-color text-center">
            <h1 class="mt-5">Авторизация</h1>
        </div>

        <div class="row mx-0">
            <div class="col-12 row mx-0 mb-4">
                <div class="col-12 col-sm-4 mx-auto text-center">
                    <label class="col-form-label mt-2 mr-2">
                        Логин/Регистрация через ВК <i class="fa fa-arrow-right"></i>
                    </label>
                    <button class="btn px-3 btn-orange" @click.prevent="vkAuth">
                        <i class="fa fa-vk"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 col-md-9 col-xl-4 mx-auto px-0 py-4">
                <b-form class="login-form" @submit.prevent="Login" autocomplete="off">
                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3 px-0">
                                <label class="col-form-label mr-0 mr-lg-3">Ваш логин</label>
                            </div>
                            <div class="col-9">
                                <input class="form-control" required type="text" v-model="payload.login" aria-label="login">
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="input-group mb-4">
                            <div class="col-3 px-0">
                                <label class="col-form-label mr-0 mr-lg-3">Ваш пароль</label>
                            </div>
                            <div class="col-9">
                                <input class="form-control" required type="password" v-model="payload.password" aria-label="password">
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-0 col-lg-3 px-0"></div>
                        <div class="col-12 col-lg-9">
                            <alert :message="response"></alert>
                            <div class="clearfix"></div>
                        </div>
                    </div>

                    <div class="row mx-0 justify-content-end">
                        <div class="col-0 col-lg-3 px-0"></div>
                        <div class="col-12 col-sm-9">
                            <div class="col-12 col-md-9 mx-auto px-0">
                                <small>
                                    <router-link class="white-color" :to="{ path: '/forgotPassword' }">
                                        Забыли пароль?
                                    </router-link>
                                </small>

                                <button class="btn btn-green w-100 mt-2" @click.prevent="Login"
                                        :disabled="isSubmitting">
                                    Войти!
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="row mx-0 my-4 justify-content-end">
                        <div class="col-0 col-lg-3 px-0"></div>
                        <div class="col-12 col-sm-9">
                            <div class="col-12 col-md-9 mx-auto px-0">
                                <span class="font-weight-bold">Еще не зарегистрированы?</span>
                                <router-link class="btn btn-orange w-100 mt-3" :to="{ path: '/registration' }">
                                    Регистрируйтесь бесплатно!
                                </router-link>
                            </div>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </div>
</template>

<script>
    import Alert from '@/components/Alert'

    export default {
        name: 'login',
        components: {
            Alert
        },
        data() {
            return {
                response: null,
                isSubmitting: false,
                payload: {
                    login: '',
                    password: ''
                }
            }
        },
        computed: {
            userData() {
                return this.$store.getters['userData']
            }
        },
        methods: {
            vkAuth(){
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.get(
                    this.$_config.baseUrl + '/Api/authViaVk',
                ).then((resp) => {
                    if (resp.data && resp.data.status) {
                        if (resp.data.status === "ok") {
                            window.location =  resp.data.data.url;
                            return true
                        } else {
                            this.response = {status: resp.data.status, msg: resp.data.msg}
                            this.isSubmitting = false
                            return false
                        }
                    }
                }).catch(() => {
                    this.response = {status: 'error', msg: 'Что-то пошло не так.Попробуйте позже'}
                    this.isSubmitting = false
                })
            },
            Login() {
                this.response = {status: 'loading'}
                this.isSubmitting = true
                this.axios.post(
                    this.$_config.baseUrl + '/Api/Login', {
                        authorize: this.payload
                    }
                ).then((userData) => {
                    let data = userData.data
                    this.isSubmitting = false
                    this.response = data
                    if (data.status === 'ok') {
                        if(data.data.postMessage == 1){
                          window.ReactNativeWebView.postMessage(JSON.stringify({
                            user: data.data.id,
                            type: "fetch",
                            message: "Success"
                          }))
                        }
                        this.$store.dispatch('fetchApiData')
                        this.$store.dispatch('fetchUserData')
                        this.response = {status: 'ok', msg: 'Добро пожаловать'}
                        this.$store.dispatch('fetchMessages')
                        this.$store.dispatch('getWheelStats')
                        this.$store.dispatch('fetchMainSettings')
                        this.$store.dispatch("checkUserFreeRound")
                    }
                }).catch((err) => {
                    if (err) {
                        this.isSubmitting = false
                        this.response = {status: 'error', msg: err.response.data.errors}
                    }
                })
            }
        },
        mounted() {
            if (this.userData) {
                this.$router.push({name: 'home'})
            }
        },
        watch: {
            userData() {
                if (this.userData) {
                    this.$router.push({path: '/'})
                }
            }
        }
    }
</script>

<style scoped>
    .login-form input,
    .login-form select {
        border-radius: 20px !important;
        height: 35px;
        border: none;
    }

    .col-form-label {
        font-size: 14px;
    }
</style>
