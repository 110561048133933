<template>
  <div class="freespins-container col-12 mt-3 mt-lg-5 mx-auto px-0">
    <b-list-group class="col-12 mx-auto pr-0" v-if="freespinsData.length">
      <b-list-group-item v-for="(freespin, idx) in freespinsData" :key="idx" class="message-item mb-4 white-color">
        <b-row :key="idx">
          <div class="col-4 col-md-3 col-lg-2">
            <img :src="$config.storageUrl +'/'+ freespin.game.picture.replace(/^\/|\/$/g, '')"
                 alt="LentaSlot онлайн Казино"
                 class="mx-auto freespin-img">
          </div>
          <div class="col-8 col-md-9 col-lg-10">
            <span>{{ freespin.game.name }}</span><br>
            <span>Вам доступно {{ freespin.count }} фриспинов</span>
            <div class="col-12 px-0">
              <button class="btn btn-green w-100 my-2" @click="launchUrl(freespin)">
                Играть
              </button>
            </div>
          </div>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <div class="text-center" v-else>
      <span class="text-white">У вас пока нет фриспинов</span>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Freespins',
  computed: {
    freespinsData() {
      return this.$store.getters['getFreespins']
    }
  },
  methods: {
    launchUrl(freespin) {
      const gameName = freespin.game.name.split(' ').join('-')
      return this.$router.push({
        name: 'TheGame',
        params: {
          gameName: gameName,
          gameId: freespin.game.id,
          game: freespin.game,
          roundId: freespin.id,
          provider: freespin.system,
          isLive: freespin.game.isLive,
          restoring: false
        }
      })
    }
  }
}
</script>

<style scoped>
.freespin-img {
  width: 75px;
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
  border-radius: 7px;
}
</style>
