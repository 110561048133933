<template>
    <div class="games-container text-white" v-if="selectedTab">
        <div class="row mx-0">
            <div class="col-12 col-lg-9 px-lg-2 games px-0">
                <b-card no-body v-if="games" class="col-12 px-0">
                    <b-tabs card vertical
                            class="games-tabs row justify-content-between"
                            id="games-tabs"
                            v-model="currentTab"
                            nav-wrapper-class="w-100"
                            ref="gamesTabs"
                    >
                        <div class="row mx-0 w-100">
                            <div class="col-10 col-lg-12 px-0">
                                <b-form class="search-form" autocomplete="off">
                                    <div class="col-12">
                                        <b-form-group>
                                            <b-form-input v-model="gamesSearchText" placeholder="Поиск игр"
                                                          class="border-0 text-white" aria-label="search-input"
                                            ></b-form-input>
                                            <b-button type="submit"
                                                      class="searchButton text-white position-absolute border-0"
                                                      aria-label="search-button">
                                                <i class="fas fa-search"></i>
                                            </b-button>
                                            <b-button class="removeButton text-white position-absolute border-0"
                                                      v-if="gamesSearchText" aria-label="clear-button"
                                                      @click.stop.prevent="gamesSearchText = '' , selectedTab=searchGamev2()">
                                                <i class="fa fa-remove"></i>
                                            </b-button>
                                        </b-form-group>
                                    </div>
                                </b-form>
                            </div>

                            <div class="col-2 px-0 d-lg-none">
                                <b-navbar-toggle target="nav-collapse-filter" id="mobile-filters-toggler"
                                                 aria-expanded="true">
                                </b-navbar-toggle>
                            </div>

                            <b-navbar toggleable="lg" type="dark" variant="info" class="p-0 float-right d-lg-none">
                                <b-collapse id="nav-collapse-filter" is-nav class="pb-5">
                                    <div class="col-12 px-0">
                                        <b-navbar-nav class="">
                                            <div class="categories my-3 px-3">
                                                <h5 class="mb-2">Категории игр:</h5>
                                                <a v-for="(tab, idx) in games" :key="idx"
                                                   @click.prevent="changeTab(idx)"
                                                   class="btn filter-btn px-2 py-1 mx-1 my-1">{{tab.name}}</a>
                                            </div>

                                            <div class="companies mx-3">
                                                <h5 class="my-4">Производители игр:</h5>
                                                <div class="row company-logo-container justify-content-around mx-0">
                                                    <a v-for="(company, index) in companies" :key="index">
                                                        <img :src="company.img" class="company-logo w-100 mb-2"
                                                             :alt="company.name">
                                                    </a>
                                                </div>
                                            </div>
                                        </b-navbar-nav>
                                    </div>
                                </b-collapse>
                            </b-navbar>
                        </div>
                        <b-tab v-for="(tab, idx, index) in games" :key="idx" class="col-12 px-0"
                               :title-item-class="[index > showTabsCount && !isMobile? 'd-none' : '']"
                               v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }"
                        >
                            <template slot="title" >
                                <div class="tab-title py-2 px-1 col-12 text-center category-item"
                                     @click.prevent="changeTab(idx)" :id="idx">
                                    <img :src="getIconForTab(index)" :alt="tab.name"><br>
                                    <span class="tab-name d-inline-block white-color">{{tab.name}}</span>
                                    <div class="category-item-effect hidden-bg position-absolute h-100 w-100"></div>
                                </div>
                            </template>
                        </b-tab> 

                        <GameTabTemplate 
                            :tab="selectedTab">
                        </GameTabTemplate>
                        <b-tab title-item-class="search-tab" :key="randomKey" class="col-12 px-0"
                               v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                            <h3 class="text-center page-title no-results" v-if="!foundedGames.length">
                                По запросу ничего не найдено
                            </h3>
                            <div class="row justify-content-around games-list-row no-gutters px-0"
                                 v-lazy-container="{ selector: 'img', loading: require('@/../public/images/icons/game-images-loader.svg') }">
                                <div class="game-item-col col-6 col-md-2" v-for="(game, i) in foundedGames"
                                     :key="'founded'+i">
                                    <GameItemPreview :game="game"></GameItemPreview>
                                </div>
                            </div>
                        </b-tab>

                        <b-tab v-if="showTabsExpander"
                               no-body empty
                               title-item-class="toggler-tab"
                               title-link-class="row mx-0 h-100"
                        >
                            <template slot="title" class="h-100">
                                <div class="px-0 row mx-0 h-100">
                                    <button @click="expandTabs" type="button"
                                            id="desktop-filters-toggler"
                                            class="navbar-toggler my-auto">
                                        <span class="navbar-toggler-icon"></span>
                                        <span class="tab-name d-inline-block white-color"> Показать все</span>
                                    </button>
                                </div>
                            </template>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
            <div class="col-3 filters d-none d-lg-block">
                <div class="categories mb-3">
                    <h5 class="mb-2">Категории игр:</h5>
                    <a v-for="(tab, idx) in games" :key="idx"
                       @click="changeTab(idx)"
                       class="btn filter-btn px-2 py-1 mx-1 my-1 ll">{{tab.name}}</a>
                </div>

                <div class="companies">
                    <h5 class="my-4">Производители игр:</h5>
                    <div class="row mx-0">
                        <a v-for="(company, index) in companies" :key="index" class="mx-1">
                            <img :src="company.img" class="company-logo w-100 mx-auto mb-2" :alt="company.name">
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div v-else class="row justify-content-center w-100">
        <i class="fa fa-spinner fa-spin fa-fw my-5 orange-color"></i>
    </div>
</template>

<script>
    import GameItemPreview from '@/components/games/GameItemPreview'
    import GameTabTemplate from "./GameTabTemplate";

    export default {
        data() {
            return {
                selectedTab: null,
                searchTimeoutId: null,
                foundedGames: [],
                showTabsCount: 5,
                currentTab: 0,
                currentTabName: 'Популярные',
                gamesSearchText: '',
                selectedProviders: [],
                currentTabIndex: '',
                providerSearchText: '',
                companyName: '',
                isShown: false,
                logosCount: 17,
                shownCategoriesCount: 10,
                randomKey: '',
                showTabsExpander: false,
                tabIcons: [
                    require('@/../public/images/icons/filter-icons/cards.png'),
                    require('@/../public/images/icons/filter-icons/new.png'),
                    require('@/../public/images/icons/filter-icons/jackpot.png'),
                    require('@/../public/images/icons/filter-icons/other.png'),
                    require('@/../public/images/icons/filter-icons/popular.png'),
                    require('@/../public/images/icons/filter-icons/slots.png'),
                    require('@/../public/images/icons/filter-icons/sport.png'),
                    require('@/../public/images/icons/filter-icons/top.png'),
                    require('@/../public/images/icons/filter-icons/wheel.png')
                ],
                companies: [
                    {
                        img: require('@/../public/images/company/amatic.png'),
                        name: 'amatic'
                    },
                    {
                        img: require('@/../public/images/company/apolloGames.png'),
                        name: 'amatic'
                    },
                    {
                        img: require('@/../public/images/company/aristocrat.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/aristocrat.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/egt.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/igrosoft.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/igt.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/microgaming.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/novomatic.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/playTechOriginal.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/qs-original.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/slotsoft.png'),
                        name: ''
                    },
                    {
                        img: require('@/../public/images/company/wazdan.png'),
                        name: ''
                    }
                ]
            }
        },
        components: {
            GameItemPreview,
            GameTabTemplate
        },
        methods: {
            expandTabs() {
                let _this = this
                let allTabsCount = (Object.keys(this.games)).length
                let elements = document.querySelectorAll(".games-tabs .nav-item")
                let btnTextElem = document.querySelector("#desktop-filters-toggler .tab-name")

                elements.forEach(function (item, index) {
                    let itemClassList = item.classList.value
                    if (index < allTabsCount && index > _this.showTabsCount) {
                        if (itemClassList.indexOf("d-none") !== -1) {
                            item.classList.remove("d-none");
                            btnTextElem.textContent = "Скрыть"
                        } else {
                            item.classList.add("d-none");
                            btnTextElem.textContent = "Показать все"
                        }

                    }
                })
            },
            searchGamev2() {
                if (this.gamesSearchText === '') {
                    return true
                }
                const providers = this.selectedProviders
                const games = []
                const gameUniqueIds = []
                this.foundedGames = []
                const searchInGames = this.games[this.currentTabIndex].games
                for (const i in searchInGames) {
                    if (!searchInGames.hasOwnProperty(i)) {
                    continue
                    }
                    const cg = searchInGames[i]
                    const name = cg.name.toLowerCase()
                    const gameProvider = cg.provider

                    if (this.gamesSearchText === '') {
                    if (providers.length > 0) {
                        if (providers.indexOf(gameProvider) !== -1) {
                        if (!cg.hasOwnProperty('unique_id')) {
                            continue
                        }
                        if (gameUniqueIds.indexOf(cg.unique_id) === -1) {
                            games.push(cg)
                            gameUniqueIds.push(cg.unique_id)
                        }
                        }
                    }
                    } else {
                    if (name.indexOf(this.gamesSearchText.toLowerCase()) !== -1) {
                        if (providers.length > 0) {
                        if (providers.indexOf(gameProvider) !== -1) {
                            if (!cg.hasOwnProperty('unique_id')) {
                            continue
                            }
                            if (gameUniqueIds.indexOf(cg.unique_id) === -1) {
                            games.push(cg)
                            gameUniqueIds.push(cg.unique_id)
                            }
                        }
                        } else {
                        games.push(cg)
                        gameUniqueIds.push(cg.unique_id)
                        }
                    }
                    }
                }

                this.foundedGames = games
                let gamesFound = false
                if (games.length > 0) {
                    gamesFound = true
                }
                this.selectedTab = {
                    games: games,
                    gamesFound: gamesFound,
                    searchText: this.gamesSearchText,
                    name: this.games[this.currentTabIndex].name
                }
                return true
            },
            changeTab (tabId) {
                if (this.isMobile) {
                    // horizontal scroll
                    let activeTab = document.querySelector('.nav-item .nav-link.active')
                    document.querySelector('.nav-tabs').scrollLeft = activeTab.offsetLeft
                    let collapseClassList = document.getElementById("nav-collapse-filter").classList.value
                    if (collapseClassList.indexOf("show") !== -1) {
                        document.getElementById("mobile-filters-toggler").click()
                    }
                    document.querySelector('.games-container').scrollIntoView()
                }

                const allTabs = document.querySelectorAll('.games-tabs .nav-link')
                allTabs.forEach(function (item) {
                    item.classList.remove('active')
                })
                const activeTab = document.getElementById(tabId)
                activeTab.parentElement.classList.add('active')

                if (this.games[tabId].games.length == 0) {
                    this.$store.dispatch('fetchGamesByProvidersv2', tabId)
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } else {
                    this.currentTabIndex = tabId
                    return this.searchGamev2()
                } 
            },
            setTimeout(callback, timeout) {
                setTimeout(callback, timeout)
            },
            getIconForTab(idx) {
                if (idx > this.tabIcons.length) {
                    idx %= this.tabIcons.length
                }

                return this.tabIcons[idx] ? this.tabIcons[idx] : this.tabIcons[0]
            },

        },
        updated () {
            if (this.games && this.gamesSearchText === '') {
            if (typeof this.currentTabIndex === 'undefined' || this.currentTabIndex === '') {
                this.currentTabIndex = Object.keys(this.games)[0]
            }
              this.selectedTab = this.games[this.currentTabIndex]
            }
            if (!this.isMobile) {
                let mainElem = document.querySelector(".games-tabs")
                if (!mainElem) {
                    return this.showTabsCount
                }
                let liElem = document.querySelector(".games-tabs .nav-item")
                let width = mainElem.offsetWidth
                let liWidth = liElem.offsetWidth

                let count = Math.floor(width / liWidth)
                this.showTabsCount = count - 1
                let countAllTabs = 0

                if (this.games) {
                    countAllTabs = (Object.keys(this.games)).length
                }
                if (countAllTabs <= count) {
                    count = countAllTabs
                } else {
                    this.showTabsExpander = true
                }
                return count
            }
        },
        computed: {
            games () {
                const allGames = this.$store.getters.games
                let availableGames = null
                if (!allGames) {
                return null
                }
                availableGames = {}
                for (const tabKey in allGames) {
                const tabOptions = allGames[tabKey].opts

                if (
                    (tabOptions.hasOwnProperty('android') && !tabOptions.android && this.$_config.isAndroid()) ||
                    (tabOptions.hasOwnProperty('ios') && !tabOptions.ios && this.$_config.isIos()) ||
                    (tabOptions.hasOwnProperty('desktop') && !tabOptions.desktop && !this.$_config.environment.mobile)
                ) {
                    continue
                }

                availableGames[tabKey] = {
                    hasNewIcon: allGames[tabKey].hasNewIcon ? allGames[tabKey].hasNewIcon : false,
                    icon: allGames[tabKey].icon,
                    name: allGames[tabKey].name,
                    games: [],
                    auth: !!allGames[tabKey].hasOwnProperty('need_auth'),
                    role: allGames[tabKey].role ? allGames[tabKey].role : null,
                    openAsFirstTab: allGames[tabKey].openAsFirstTab ? allGames[tabKey].openAsFirstTab : false,
                }

                for (const i in allGames[tabKey].games) {
                    if (!allGames[tabKey].games.hasOwnProperty(i)) {
                    continue
                    }
                    const game = allGames[tabKey].games[i]
                    if (
                        (game.hasOwnProperty('android') && !game.android && this.$_config.isAndroid()) ||
                        (game.hasOwnProperty('ios') && !game.ios && this.$_config.isIos()) ||
                        (game.hasOwnProperty('desktop') && !game.desktop && !this.$_config.environment.mobile) ||
                        (game.picture === '')
                    ) {
                    continue
                    }

                    availableGames[tabKey].games.push(game)
                }
            }
            return availableGames
          },
            isMobile() {
                return this.$_config.environment.mobile
            },
        },
        watch: {
            gamesSearchText (newVal, oldValue) {
                if (newVal !== '') {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true
                    this.searchGamev2()
                    }, 800)
                } else if (oldValue !== '') {
                    clearTimeout(this.searchTimeoutId)
                    this.searchTimeoutId = setTimeout(() => {
                    this.selectedTab = true
                    this.searchGamev2()
                    }, 800)
                } 
            },
            games() {
                if (this.games) {
                    this.selectedTab = this.games[(Object.keys(this.games)[0])]
                }
            },
            currentTabIndex(newVal){
                if(newVal){
                    this.selectedTab = this.games[newVal]
                }
            }
        },
        mounted() {
            this.$store.dispatch('fetchGames')
            this.$store.dispatch('checkForAliveGame')
            this.$store.dispatch("checkUserFreeRound")
            if (this.games) {
              this.currentTabIndex = Object.keys(this.games)[0]
                this.selectedTab = this.games[this.currentTabIndex]
            }
        }
    }
</script>

<style scoped>
    .tab-name {
        font-size: 12px;
    }

    .tab-pane.card-body {
        padding: 0 !important;
        min-height: 0 !important;
    }

    #desktop-filters-toggler .navbar-toggler-icon {
        margin: auto;
        display: block;
    }

    .company-logo-container {
        height: 150px;
        overflow-y: scroll;
    }

    .company-logo-container::-webkit-scrollbar {
        width: 3px;
    }

    .company-logo-container::-webkit-scrollbar-thumb {
        background-image: linear-gradient(#F2CC6E, #ffb635);
    }

    .search-form input {
        background: rgba(47, 51, 54, 0.6);
        border-radius: 20px;
        padding: 0 50px 3px 50px;
    }

    .search-form input::placeholder {
        color: white;
    }

    .searchButton i, .removeButton i {
        padding: 6px 3px;
    }

    .searchButton, .removeButton {
        top: 0;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(248, 209, 8, 0.98) 0%, rgb(248, 209, 8) 35%, rgba(155, 115, 51, 0.59) 100%);
    }

    .searchButton {
        right: 15px;
    }

    .removeButton {
        left: 15px;
    }

    .category-item-effect {
        top: 0;
        left: 0;
        background-image: url("../../../public/images/border-bg.png");
        background-size: 100% 100%;
    }

    .category-item img {
        object-fit: contain;
        width: 40px;
        height: 40px;
    }

    .category-item .hidden-bg {
        display: none;
    }

    .category-item:hover > .hidden-bg {
        display: block;
    }

    .games .card {
        background: transparent;
    }

    .filter-btn {
        background-color: rgba(255, 255, 255, 0.1);
        color: white !important;
        border-radius: 20px;
        font-size: 12px;
    }

    .filter-btn:hover {
        border: 1px solid;
    }

    .company-logo {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        border: 1px solid transparent;
    }

    .company-logo:hover {
        border: 1px solid;
        background-color: transparent;
    }

    .category-item {
        min-width: 90px;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        .card,
        .tab-pane {
            padding: 0;
        }

        .no-results {
            font-size: 18px;
        }
    }

</style>
<style>
    .games-tabs .navbar-toggler-icon {
        background-image: url("../../../public/images/filter-collapse.png") !important;
    }

    .games-tabs .nav {
        flex-direction: unset !important;
    }

    .games-tabs .nav-link {
        border: none !important;
    }

    .games-tabs .nav-link.active {
        background: transparent !important;
    }

    .games-tabs .nav-link.active > .category-item .category-item-effect {
        display: block;
    }

    .games-tabs .nav-tabs {
        padding-left: 0;
        padding-right: 0;
    }

    @media (min-width: 320px) and (max-width: 1366px) {
        .games-tabs .nav-tabs {
            overflow-x: auto;
            flex-wrap: inherit !important;
        }

        .games-tabs .nav-tabs::-webkit-scrollbar,
        .games-tabs .nav-tabs::-webkit-scrollbar-thumb {
            display: none;
        }
    }
</style>
